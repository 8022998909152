import React, { useState, useEffect } from "react";
import Header from "./Header";
import Headerpanel from "./Headerpanel";
import axios from "axios";
//import { ToastContainer,toast } from "react-toastify/dist/components";
function Home() {
  const [tst, setTst] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("toast");

    return saved || false;

  });
  const [mis,setMis] = useState([]);

  const uid = localStorage.getItem('uid');

  function checkToast() {
    //const st = localStorage.getItem('toast')
    localStorage.removeItem('toast');
    setTst(false);
    console.log(tst);
    
  }
  function AddLibrary(urlOfTheLibrary) {
    const script = document.createElement('script');
    script.src = urlOfTheLibrary;
    script.async = true;
    document.body.appendChild(script);
  }
  function fetchMis(){
    axios.get('https://api.entmcq.com/api/admin-mis',{headers:{"Authorization" : `Bearer ${uid}`}})
    .then((res)=>{
      const data = res.data;
      console.log(data);
      setMis(data);
    })
  }
  useEffect(()=>{
    fetchMis();
  },[]);
  return (
    <React.Fragment>


      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">

          <Header />
          {tst && (<div
            class="bs-toast toast toast-placement-ex m-2 show bg-success top-0 end-0 fade show"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            data-delay="2000"

          >
            <div class="toast-header">
              <i class="bx bx-bell me-2"></i>
              <div class="me-auto fw-semibold">Alert</div>
              {/* <small>11 mins ago</small> */}
              <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={checkToast}></button>
            </div>
            <div class="toast-body">Login Successfull</div>
          </div>)}

          <div className="layout-page">


            <Headerpanel />


            <div className="content-wrapper">


              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row">
                  <div className="col-lg-12 mb-4 order-0">
                    <div className="card">
                      <div className="d-flex align-items-end row">
                        <div className="col-sm-7">
                          <div className="card-body">
                            <h5 className="card-title text-primary">Welcome Admin! </h5>
                            <p className="mb-4">
                              Welcome Admin
                            </p>


                          </div>
                        </div>
                        <div className="col-sm-5 text-center text-sm-left">
                          <div className="card-body pb-0 px-0 px-md-4">
                            <img
                              src="assets/img/illustrations/man-with-laptop-light.png"
                              height="140"
                              alt="View Badge User"
                              data-app-dark-img="illustrations/man-with-laptop-dark.png"
                              data-app-light-img="illustrations/man-with-laptop-light.png"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 col-lg-12 order-2 order-md-3 order-lg-2 mb-4">
                    <div className="card">
                      <div className="row row-bordered g-0">
                        {/* <div className="col-md-8">
                        <h5 className="card-header m-0 me-2 pb-3">Total Subscriptions</h5>
                        <div id="totalRevenueChart" className="px-2"></div>
                      </div> */}

                        <div className="col-lg-12 col-md-12 order-1">
                          <div className="row">
                            <div className="col-lg-6 col-md-12 col-6 mb-4">
                              <div className="card">
                                <div className="card-body">
                                  <div className="card-title d-flex align-items-start justify-content-between">
                                    <div className="avatar flex-shrink-0">
                                      <img
                                        src="assets/img/icons/unicons/chart-success.png"
                                        alt="chart success"
                                        className="rounded"
                                      />
                                    </div>
                                    <div className="dropdown">
                                      <button
                                        className="btn p-0"
                                        type="button"
                                        id="cardOpt3"
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <i className="bx bx-dots-vertical-rounded"></i>
                                      </button>
                                      <div className="dropdown-menu dropdown-menu-end" aria-labelledby="cardOpt3">
                                        <a className="dropdown-item" href="#;">View More</a>
                                        <a className="dropdown-item" href="#;">Delete</a>
                                      </div>
                                    </div>
                                  </div>
                                  <span className="fw-semibold d-block mb-1">Speciality</span>
                                  <h3 className="card-title mb-2">5</h3>

                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-6 mb-4">
                              <div className="card">
                                <div className="card-body">
                                  <div className="card-title d-flex align-items-start justify-content-between">
                                    <div className="avatar flex-shrink-0">
                                      <img
                                        src="assets/img/icons/unicons/wallet-info.png"
                                        alt="Credit Card"
                                        className="rounded"
                                      />
                                    </div>
                                    <div className="dropdown">
                                      <button
                                        className="btn p-0"
                                        type="button"
                                        id="cardOpt6"
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <i className="bx bx-dots-vertical-rounded"></i>
                                      </button>
                                      <div className="dropdown-menu dropdown-menu-end" aria-labelledby="cardOpt6">
                                        <a className="dropdown-item" href="#;">View More</a>
                                        <a className="dropdown-item" href="#;">Delete</a>
                                      </div>
                                    </div>
                                  </div>
                                  <span>Packages</span>
                                  <h3 className="card-title text-nowrap mb-1">3</h3>

                                </div>
                              </div>
                            </div>
                            <div className="col-6 mb-4">
                              <div className="card">
                                <div className="card-body">
                                  <div className="card-title d-flex align-items-start justify-content-between">
                                    <div className="avatar flex-shrink-0">
                                      <img src="assets/img/icons/unicons/paypal.png" alt="Credit Card" className="rounded" />
                                    </div>
                                    <div className="dropdown">
                                      <button
                                        className="btn p-0"
                                        type="button"
                                        id="cardOpt4"
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <i className="bx bx-dots-vertical-rounded"></i>
                                      </button>
                                      <div className="dropdown-menu dropdown-menu-end" aria-labelledby="cardOpt4">
                                        <a className="dropdown-item" href="#;">View More</a>
                                        <a className="dropdown-item" href="#;">Delete</a>
                                      </div>
                                    </div>
                                  </div>
                                  <span className="d-block mb-1">Payments</span>
                                  <h3 className="card-title text-nowrap mb-2">{mis.length * 95}</h3>
                                  {/* <small className="text-danger fw-semibold"><i className="bx bx-down-arrow-alt"></i> -14.82%</small> */}
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-6 mb-4">
                              <div className="card">
                                <div className="card-body">
                                  <div className="card-title d-flex align-items-start justify-content-between">
                                    <div className="avatar flex-shrink-0">
                                      <img src="assets/img/icons/unicons/cc-primary.png" alt="Credit Card" className="rounded" />
                                    </div>
                                    <div className="dropdown">
                                      <button
                                        className="btn p-0"
                                        type="button"
                                        id="cardOpt1"
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <i className="bx bx-dots-vertical-rounded"></i>
                                      </button>
                                      <div className="dropdown-menu" aria-labelledby="cardOpt1">
                                        <a className="dropdown-item" href="#;">View More</a>
                                        <a className="dropdown-item" href="#;">Delete</a>
                                      </div>
                                    </div>
                                  </div>
                                  <span className="fw-semibold d-block mb-1">Transactions</span>
                                  <h3 className="card-title mb-2">$14,857</h3>
                                  <small className="text-success fw-semibold"><i className="bx bx-up-arrow-alt"></i> +28.14%</small>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="row">

                  <div className="col-md-12 col-lg-12 order-2 mb-4">
                    <div className="card h-100">
                      <div className="card-header d-flex align-items-center justify-content-between">
                        <h5 className="card-title m-0 me-2">Transactions</h5>
                        <div className="dropdown">
                          <button
                            className="btn p-0"
                            type="button"
                            id="transactionID"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="bx bx-dots-vertical-rounded"></i>
                          </button>
                          <div className="dropdown-menu dropdown-menu-end" aria-labelledby="transactionID">
                            <a className="dropdown-item" href="#;">Last 28 Days</a>
                            <a className="dropdown-item" href="#;">Last Month</a>
                            <a className="dropdown-item" href="#;">Last Year</a>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <ul className="p-0 m-0">

                          {mis.map((obj)=>{
                            return(
                            <li className="d-flex mb-4 pb-1">
                            <div className="avatar flex-shrink-0 me-3">
                              <img src="assets/img/icons/unicons/paypal.png" alt="User" className="rounded" />
                            </div>
                            <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                              <div className="me-2">
                                <small className="text-muted d-block mb-1">{obj.name}</small>
                                <h6 className="mb-0">Basic</h6>
                              </div>
                              <div className="user-progress d-flex align-items-center gap-1">
                                <h6 className="mb-0">95</h6>
                                <span className="text-muted">GBP</span>
                              </div>
                            </div>
                          </li>
                          )
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <footer className="content-footer footer bg-footer-theme">
                <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">

                </div>
              </footer>


              <div className="content-backdrop fade"></div>
            </div>

          </div>

        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
      {AddLibrary("/assets/vendor/libs/jquery/jquery.js")}
      {AddLibrary("/assets/vendor/libs/popper/popper.js")}
      {AddLibrary("/assets/vendor/js/bootstrap.js")}
      {AddLibrary("/assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.js")}
      {AddLibrary("/assets/vendor/js/menu.js")}
      {AddLibrary("/assets/vendor/libs/apex-charts/apexcharts.js")}
      {AddLibrary("/assets/js/dashboards-analytics.js")}
      {AddLibrary("/assets/js/main.js")}

    </React.Fragment>
  )
}

export default Home