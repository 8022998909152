import React,{useState, useEffect} from 'react';
import axios from 'axios';
import Header from '../Header';
import DataTable from 'react-data-table-component';
import Headerpanel from '../Headerpanel';
function Discount() {
    const [service,setService]=useState([])
    var cnt =0;
    const uid = localStorage.getItem('uid');
    const clms = [
        
        {
          name:'Code',
          selector: row=>row.dic_code,
          sortable:true,
          wrap:true,
          maxWidth:'400px',
          cell:row=>(
            <div dangerouslySetInnerHTML={{__html: row.dis_code}}/>
          )
        },
        {
          name:'Start Date',
          selector: row=>row.dis_start_date,
          sortable:true,
          wrap:true,
          maxWidth:'400px',
          cell:row=>(
            <div dangerouslySetInnerHTML={{__html: row.dis_start_date}}/>
          )
        },
        {
          name:'End Date',
          selector: row=>row.dis_end_date,
          sortable:true,
          wrap:true,
          maxWidth:'400px',
          cell:row=>(
            <div dangerouslySetInnerHTML={{__html: row.dis_end_date}}/>
          )
        },
        {
          name:'Status',
          selector: row=>row.status,
          sortable:true,
          wrap:true,
          maxWidth:'400px',
          cell:row=>(
            <div dangerouslySetInnerHTML={{__html: row.status}}/>
          )
        },
        {
          name: "Actions",
          button: true,
          maxWidth:'200px',
          cell: (row) => (
            <div>
                                        <button class="dropdown-item" onClick={()=>editOption(row.id)}
                                          ><i class="bx bx-edit-alt me-1"></i> Edit</button>
                                        <button class="dropdown-item" onClick={()=>deleteOption(row.id)}
                                          ><i class="bx bx-trash me-1"></i> Delete </button>
                                        {/* <button class="dropdown-item" onClick={()=>setTrial(row.id)}
                                          ><i class="bx bx-trash me-1"></i> Set As Trial</button> */}
                                      </div>
          )
        }
        
      ]
    function fetchService()
    {
      axios.get('https://api.entmcq.com/api/show-discount',{headers:{"Authorization" : `Bearer ${uid}`}})
            .then((res)=>{
              const data = res.data;
              setService(data);
            })
    }
    function editOption(id){
        window.location.href='/edit-discount/'+id;
        
      }
  
      function deleteOption(id)
      {
        axios.get('https://api.entmcq.com/api/delete-discount/'+id,{headers:{"Authorization" : `Bearer ${uid}`}})
              .then((res) =>{
                console.log(res);
                //alert("Subject added successfully");
                const data = res.data;
                if(data.status=="success"){
                  // alert("Package Deactivated successfully");
                  window.location.reload();
                  
                }
                  
                else{
                  alert("Package Delete failed");
                }
                fetchService();
              })
      }
      useEffect(()=>{
        fetchService()
      },[])

    function AddLibrary(urlOfTheLibrary) {
        const script = document.createElement('script');
        script.src = urlOfTheLibrary;
        script.async = true;
        document.body.appendChild(script);
      }
        
    return (
        <React.Fragment>
        <div class="layout-wrapper layout-content-navbar">
      <div class="layout-container">
      <Header/>
        <div class="layout-page">
          
          <Headerpanel/>

          

          
          <div class="content-wrapper">
            

            <div class="container-xxl flex-grow-1 container-p-y">
              <h4 class="fw-bold py-3 mb-4"><span class="text-muted fw-light">Dashboard /</span> Discount</h4>
              <a href="/add-discount" class="btn btn-danger col-sm-2"><i class="bx bx-plus me-1"></i> Add new</a>
              
              <div class="row mt-4">
                       <div class="col-md-12">
                  <div class="card mb-4">
                    <h5 class="card-header">View Discounts</h5>
                <div class="card-body">
                  <div class="table-responsive text-nowrap">
                  <DataTable
                      columns={clms}
                      data = {service}
                      pagination
                    />
                  </div>
                </div>
                  </div>
                </div>

                
                
              </div>

              

             
              

              
            </div>
            
            <footer class="content-footer footer bg-footer-theme">
              <div class="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                
              </div>
            </footer>
            

            <div class="content-backdrop fade"></div>
          </div>
         
        </div>
        
      </div>

      
      <div class="layout-overlay layout-menu-toggle"></div>
    </div>
    {AddLibrary("/assets/vendor/libs/jquery/jquery.js")}
    {AddLibrary("/assets/vendor/libs/popper/popper.js")}
    {AddLibrary("/assets/vendor/js/bootstrap.js")}
    {AddLibrary("/assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.js")}
    {AddLibrary("/assets/vendor/js/menu.js")}
    {AddLibrary("/assets/js/dashboards-analytics.js")}
    {AddLibrary("/assets/vendor/libs/apex-charts/apexcharts.js")}
    {AddLibrary("/assets/js/main.js")}
    </React.Fragment>
    );
}

export default Discount;
