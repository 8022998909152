import React, { useState, useEffect } from 'react';
import Header from '../Header';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Headerpanel from '../Headerpanel';
import { ToastContainer, toast } from 'react-toastify';
import { isEmail } from "../../validators/Validations";
import 'react-toastify/dist/ReactToastify.css';

function ShowQuestioner() {
    const { id } = useParams();
    const [name, setFname] = useState('');
    const [mob, setMob] = useState('');
    const [em, setEm] = useState('');
    const [jobprofile, setJobprofile] = useState('');
    const [hospitalname, setHospitalName] = useState('');
    const [hospitaladdress, setHospitalAddress] = useState('');
    const [accountholder, setAccountHolder] = useState('');
    const [accountnumber, setAccountNumber] = useState('');
    const [ifsccode, setIfscCode] = useState('');
    const [contactnumber, setContactNumber] = useState('');
    const [bankname, setBankName] = useState('');
    const [jobError, setJobError] = useState(false)
    const [hospitalnameError, setHospitalnameError] = useState(false)
    const [hospitaladdressError, setHospitaladdressError] = useState(false)
    const [accountholderError, setAccountHolderError] = useState(false)
    const [accountnumberError, setAccountNumberError] = useState(false)
    const [ifscError, setIfscError] = useState(false)
    const [contactnumberError, setContactNumberError] = useState(false)
    const [banknameError, setBankNameError] = useState(false)
    const [approved,setApproved] = useState(0);
    const [notApproved,setNotApproved] = useState(0);

    function fetchUser() {
        var uid = localStorage.getItem('uid');
        axios.get('https://api.entmcq.com/api/users/' + id,{headers:{"Authorization" : `Bearer ${uid}`}})
            .then((res) => {
                const data = res.data;
                console.log(data);
                setFname(data.name);
                setEm(data.email);
                setMob(data.contact);
                //setContactNumber(data.contact);
                setJobprofile(data.job_profile);
                var hdetails = JSON.parse(data.hospital_details);
                setHospitalName(hdetails[0].hospitalname);
                setHospitalAddress(hdetails[0].hospitaladdress)
                var bdetails = JSON.parse(data.bank_details);
                setBankName(bdetails[0].bankname);
                setAccountHolder(bdetails[0].accountholder)
                setContactNumber(bdetails[0].contactnumber);
                setIfscCode(bdetails[0].ifsccode);
                setAccountNumber(bdetails[0].accountnumber);


            })
    }
    function fetchQuestions() {
        var uid = localStorage.getItem('uid');
        axios.get('https://api.entmcq.com/api/myquestions/'+id,{headers:{"Authorization" : `Bearer ${uid}`}})
          .then((res) => {
            const data = res.data;
            // setQuesitons(data);
            var ap = 0;
            var nap = 0;
            data.map((obj)=>{
                if(obj.status == "active"){
                    ap ++;
                }
                else{
                    nap++;
                }
            })
            setApproved(ap);
            setNotApproved(nap);
          });
      }

    useEffect(() => {
        fetchUser()
        fetchQuestions();
    }, [])
    return (
        <React.Fragment>
            <div class="layout-wrapper layout-content-navbar">
                <div class="layout-container">
                    <Header />
                    <div class="layout-page">
                        <Headerpanel />
                        <div class="content-wrapper">
                            <div class="container-xxl flex-grow-1 container-p-y">
                                <h4 class="fw-bold py-3 mb-4"><span class="text-muted fw-light">Dashboard /</span> Questioner</h4>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="card mb-4">
                                            <h5 class="card-header">Personal Info</h5>
                                            <div class="card-body">
                                                <div class="mb-3">
                                                    <div class="form-label">Email ID</div>
                                                    <input type="email" class="form-control" placeholder="Email ID" value={em} onChange={(em) => { setEm(em.target.value) }} />
                                                </div>
                                                <div class="mb-3">
                                                    <div class="form-label">First Name</div>
                                                    <input type="text" class="form-control" placeholder="First Name" value={name} onChange={(fname) => { setFname(fname.target.value) }} />
                                                </div>
                                                {/* <div class="mb-3">
                                        <div class="form-label">Last Name</div>
                                        <input type="text" class="form-control" placeholder="Last Name" value={lname} onChange={(lname)=>{setLname(lname.target.value)}}/>
                                    </div> */}
                                                <div class="mb-3">
                                                    <label class="form-label">Mobile Number </label>
                                                    <input type="text" class="form-control" placeholder="Mobile Number" value={mob} onChange={(mob) => { setMob(mob.target.value) }} />
                                                </div>
                                                <div class="mb-3">
                                                    <label class="form-label">Mobile Number</label>
                                                    <input type="text" class="form-control" placeholder="Mobile Number" value={mob} onChange={(mob) => {
                                                        setMob(mob.target.value)
                                                    }} />
                                                </div>
                                                <div class="mb-3">
                                                    <label class="form-label">Job Profile</label>
                                                    <input type="text" class="form-control" placeholder="Job Profile" value={jobprofile} onChange={(jobprofile) => {
                                                        setJobprofile(jobprofile.target.value)
                                                        setJobError(false)
                                                    }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="card mb-4">
                                            <h5 class="card-header">Hospital Details</h5>
                                            <div class="card-body">
                                                <div class="mb-3">
                                                    <label class="form-label">Name<span class="text-danger">* {hospitalnameError ? ("Invalid Name") : ("")}</span></label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" placeholder="Name" value={hospitalname} onChange={(hospitalname) => {
                                                            setHospitalName(hospitalname.target.value)
                                                            setHospitalnameError(false)
                                                        }} />
                                                        {/* <button class="btn"><i class="bi bi-eye"></i></button> */}
                                                    </div>
                                                </div>
                                                <div class="mb-3">
                                                    <label class="form-label">Address <span
                                                        class="text-danger">* {hospitaladdressError ? ("Invalid Address") : ("")}</span></label>
                                                    <div class="input-group">
                                                        <textarea type="" class="form-control"
                                                            placeholder="Address" value={hospitaladdress}
                                                            onChange={(hospitaladdress) => {
                                                                setHospitalAddress(hospitaladdress.target.value)
                                                                setHospitaladdressError(false)
                                                            }} />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="card mb-4">
                                            <h5 class="card-header">Bank Details</h5>
                                            <div class="card-body">
                                                <div class="mb-3">
                                                    <label class="form-label">Account Holder Name<span
                                                        class="text-danger">* {accountholderError ? ("Invalid Account Holder Name") : ("")}</span></label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control"
                                                            placeholder="Account Holder Name" value={accountholder} onChange={(accountholder) => {
                                                                setAccountHolder(accountholder.target.value)
                                                                setAccountHolderError(false)
                                                            }} />
                                                        {/* <button class="btn"><i class="bi bi-eye"></i></button> */}
                                                    </div>
                                                </div>
                                                <div class="mb-3">
                                                    <label class="form-label">Account Number<span class="text-danger">* {accountnumberError ? ("Invalid Account Number") : ("")}</span></label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" placeholder="Account Number" value={accountnumber} onChange={(accountnumber) => {
                                                            setAccountNumber(accountnumber.target.value)
                                                            setAccountNumberError(false)
                                                        }} />
                                                        {/* <button class="btn"><i class="bi bi-eye"></i></button> */}
                                                    </div>
                                                </div>
                                                <div class="mb-3">
                                                    <label class="form-label">IFSC Code<span class="text-danger">* {ifscError ? ("Invalid IFSC") : ("")}</span></label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" placeholder="IFSC Code" value={ifsccode} onChange={(ifsccode) => {
                                                            setIfscCode(ifsccode.target.value)
                                                            setIfscError(false)
                                                        }} />
                                                        {/* <button class="btn"><i class="bi bi-eye"></i></button> */}
                                                    </div>
                                                </div>
                                                <div class="mb-3">
                                                    <label class="form-label">Mobile Number<span
                                                        class="text-danger">* {contactnumberError ? ("Invalid Contact Number") : ("")}</span></label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control"
                                                            placeholder="Mobile Number" value={contactnumber} onChange={(contactnumber) => {
                                                                setContactNumber(contactnumber.target.value)
                                                                setContactNumberError(false)
                                                            }} />
                                                        {/* <button class="btn"><i class="bi bi-eye"></i></button> */}
                                                    </div>
                                                </div>
                                                <div class="mb-3">
                                                    <label class="form-label">Bank Name<span class="text-danger">* {banknameError ? ("Invalid Bank Name") : ("")}</span></label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" placeholder="Bank Name" value={bankname} onChange={(bankname) => {
                                                            setBankName(bankname.target.value)
                                                            setBankNameError(false)
                                                        }} />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <h5 class="card-header">Question Details</h5>
                                        <div class="row">
                                            <div className="col-lg-6 col-md-12 col-6 mb-4">
                                                <div class="card mb-4">

                                                    <div className="card">
                                                        <div className="card-body">

                                                            <span className="fw-semibold d-block mb-1">Approved</span>
                                                            <h3 className="card-title mb-2">{approved}</h3>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-6 mb-4">
                                                <div class="card mb-4">

                                                    <div className="card">
                                                        <div className="card-body">

                                                            <span className="fw-semibold d-block mb-1">Not Approved</span>
                                                            <h3 className="card-title mb-2">{notApproved}</h3>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-6 mb-4">
                                                <div class="card mb-4">

                                                    <div className="card">
                                                        <div className="card-body">

                                                            <span className="fw-semibold d-block mb-1">Earning</span>
                                                            <h3 className="card-title mb-2">{approved*5}</h3>
                                                            <span className="pond" style={{fontSize:16}}> <i class='bx bx-pound'></i>5/Question</span>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ShowQuestioner;