import React, { useState, useEffect } from 'react';
import Header from '../Header';
import axios from 'axios';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Headerpanel from '../Headerpanel';
import { ToastContainer, toast } from 'react-toastify';
import { Triangle } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';


function EditDiscount() {
    const {id} = useParams();
    const [showProcess, setShowProcess] = useState(false);
    const [code, setCode] = useState('abcd123');
    const [amt, setAmt] = useState(0);
    const [type, setType] = useState('amount');
    const [sdate, setSdate] = useState(new Date());
    const [edate, setEdate] = useState(new Date());
    const [status, setStatus] = useState('active');

    const uid = localStorage.getItem('uid');

    const [error, setError] = useState(false);
    const [isCodeError, setCodeError] = useState(false)
    const [isAmtError, setAmtError] = useState(false)
    const [isTypeError, setTypeError] = useState(false)
    const [isSdateError, setSdateError] = useState(false)
    const [isEdateError, setEdateError] = useState(false)
    const [isStatusError, setStatusError] = useState(false)



    const noStyle = {
        display: 'none',
    }

    const yesStyle = {
        display: 'block',
    }
    const warn = { borderWidth: 1, borderColor: '#f44336' }
    const nowarn = { borderWidth: 1, borderColor: '#d9dee3' }

    function AddLibrary(urlOfTheLibrary) {
        const script = document.createElement('script');
        script.src = urlOfTheLibrary;
        script.async = true;
        document.body.appendChild(script);
    }

    function randomString(length, chars) {
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }


    function storeDiscount() {
        if (!code) {
            setCodeError(true)
            return false;
        }
        else if (!amt) {
            setAmtError(true)
            return false;
        }
        else if (!type) {
            setTypeError(true)
            return false;
        }
        else if (!sdate) {
            setSdateError(true)
            return false
        }
        else if (!edate) {
            setEdateError(true)
            return false
        }
        else {
            var sdt = new Date(sdate);
            var edt = new Date(edate)
            var separator = '/'
            var sd = `${sdt.getDate() < 10 ? `0${sdt.getDate()}` : `${sdt.getDate()}`}${separator}${(sdt.getMonth() + 1) < 10 ? `0${sdt.getMonth() + 1}` : `${sdt.getMonth() + 1}`}${separator}${sdt.getFullYear()}`;
            var ed = `${edt.getDate() < 10 ? `0${edt.getDate()}` : `${edt.getDate()}`}${separator}${(edt.getMonth() + 1) < 10 ? `0${edt.getMonth() + 1}` : `${edt.getMonth() + 1}`}${separator}${edt.getFullYear()}`
            const pData = {
                id:id,
                code: code,
                amt: amt,
                type: type,
                sdate: sd,
                edate: ed,
                status: status,
            }
            // console.log(pData)
            axios.post('https://api.entmcq.com/api/update-discount',pData,{headers:{"Authorization" : `Bearer ${uid}`}})
            .then((res)=>{
                console.log(res)
                var data = res.data;
                if(data.status == 'success'){
                    toast.success(data.msg);
                }
            })
        }
    }
    function fetchDiscount(){
        axios.get('https://api.entmcq.com/api/edit-discount/'+id,{headers:{"Authorization" : `Bearer ${uid}`}})
        .then((res)=>{
            let data = res.data;
            if(data.length>0){
                data = data[0];
                console.log(data);
                setCode(data.dis_code);
                setAmt(data.dis_amt);
                setType(data.dis_type);
                var ssdt = data.dis_start_date.split('/');
                var esdt = data.dis_end_date.split('/');
                setSdate(new Date(ssdt[2]+"-"+ssdt[1]+"-"+ssdt[0]))
                setEdate(new Date(esdt[2]+"-"+esdt[1]+"-"+esdt[0]))
                setStatus(data.status)
                // console.log(sdate.toISOString().substring(0,10))
                console.log('sdate',sdate);
            }
        })
    }
    useEffect(() => {
        // console.log(id);
        fetchDiscount()
    }, [])

    return (
        <React.Fragment>
            {showProcess && (<div style={{ marginTop: 20 + "%", justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                <Triangle
                    height="80%"
                    width="80"
                    color="#4fa94d"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{ justifyContent: 'center', alignContent: 'center' }}
                    visible={showProcess}
                />
            </div>)}
            <div className="layout-wrapper layout-content-navbar" style={showProcess ? noStyle : yesStyle}>
                <div class="layout-container">


                    <Header />
                    {error && (<div
                        class="bs-toast toast toast-placement-ex m-2 show bg-warning top-0 end-0 fade show"
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                        data-delay="2000"

                    >
                        <div class="toast-header">
                            <i class="bx bx-bell me-2"></i>
                            <div class="me-auto fw-semibold">Alert</div>
                            {/* <small>11 mins ago</small> */}
                            <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={() => { setError(false) }}></button>
                        </div>
                        <div class="toast-body">somefields are empty</div>
                    </div>)}
                    <div class="layout-page">

                        <Headerpanel />




                        <div class="content-wrapper">


                            <div class="container-xxl flex-grow-1 container-p-y">
                                <h4 class="fw-bold py-3 mb-4"><span class="text-muted fw-light">Dashboard /</span> Discount</h4>

                                <div class="row">

                                    <div class="col-md-12">
                                        <div class="card mb-4">
                                            <h5 class="card-header">Add Discount</h5>
                                            <div class="card-body demo-vertical-spacing demo-only-element">
                                                <div>
                                                    <input type="hidden" value="" />
                                                    <label for="defaultFormControlInput" class="form-label">Discount Code</label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="defaultFormControlInput"
                                                        placeholder=""
                                                        aria-describedby="defaultFormControlHelp"
                                                        value={code}
                                                        style={isCodeError ? warn : nowarn}
                                                        onChange={(packagename) => {
                                                            setCode(packagename.target.value)
                                                            setCodeError(false)
                                                        }}
                                                    />
                                                </div>

                                                <div>
                                                    <label for="defaultFormControlInput" class="form-label">Discount Amount/Percentage</label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="defaultFormControlInput"
                                                        placeholder=""
                                                        aria-describedby="defaultFormControlHelp"
                                                        value={amt}
                                                        style={isAmtError ? warn : nowarn}
                                                        onChange={(packagename) => {
                                                            setAmt(packagename.target.value)
                                                            setAmtError(false)
                                                        }}
                                                    />
                                                </div>

                                                <div>
                                                    <input type="hidden" value="" />
                                                    <label for="defaultFormControlInput" class="form-label">Discount Type</label>
                                                    <select className="form-select" id="exampleFormControlSelect1" aria-label="Default select example"
                                                        onChange={qstatus => setType(qstatus.target.value)}
                                                        value={type}>
                                                        <option value="amount">Amount</option>
                                                        <option value="percentage">Percentage</option>
                                                    </select>
                                                </div>


                                                <div>

                                                    <label for="defaultFormControlInput" class="form-label">Start Date</label>
                                                    <input
                                                        type="Date"
                                                        class="form-control"
                                                        id="defaultFormControlInput"
                                                        value={sdate.toISOString().substring(0,10)}
                                                        onChange={(pricing) => {
                                                            setSdate(pricing.target.value)
                                                            setSdateError(false)
                                                        }}
                                                        style={isSdateError ? warn : nowarn}
                                                    />
                                                </div>
                                                <div>

                                                    <label for="defaultFormControlInput" class="form-label">End Date</label>
                                                    <input
                                                        type="Date"
                                                        class="form-control"
                                                        id="defaultFormControlInput"
                                                        value={edate.toISOString().substring(0,10)}
                                                        onChange={(pricing) => {
                                                            setEdate(pricing.target.value)
                                                            setEdateError(false)
                                                        }}
                                                        style={isEdateError ? warn : nowarn}
                                                    />
                                                </div>
                                                <div>

                                                    <label for="defaultFormControlInput" class="form-label">Status</label>
                                                    <select className="form-select" id="exampleFormControlSelect1" aria-label="Default select example"
                                                        onChange={qstatus => setStatus(qstatus.target.value)}
                                                        value={status}>
                                                        <option value="active">Active</option>
                                                        <option value="pending">Inactive</option>
                                                    </select>
                                                </div>




                                                <div class="mb-3">
                                                    <button class="btn btn-primary d-grid w-100" type="button" style={{ backgroundColor: '#188ccc' }} onClick={storeDiscount}>Store</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                </div>







                            </div>

                            <footer class="content-footer footer bg-footer-theme">
                                <div class="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">

                                </div>
                            </footer>


                            <div class="content-backdrop fade"></div>
                        </div>

                    </div>

                </div>


                <div class="layout-overlay layout-menu-toggle"></div>
            </div>
            <ToastContainer />
            {AddLibrary("/assets/vendor/libs/jquery/jquery.js")}
            {AddLibrary("/assets/vendor/libs/popper/popper.js")}
            {AddLibrary("/assets/vendor/js/bootstrap.js")}
            {AddLibrary("/assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.js")}
            {AddLibrary("/assets/vendor/js/menu.js")}
            {AddLibrary("/assets/js/dashboards-analytics.js")}
            {AddLibrary("/assets/vendor/libs/apex-charts/apexcharts.js")}
            {AddLibrary("/assets/js/main.js")}
        </React.Fragment>
    )
}

export default EditDiscount;