import Header from '../Header';
import axios from "axios";
import React, { useState, useEffect } from 'react';
import Headerpanel from '../Headerpanel';
function TrialFeedback() {
    const [subcription, setSubcription] = useState([])
    const [error,setError] = useState(false);
    
    function fetchTrial() {

        axios.get('https://api.entmcq.com/api/feedbacks')
            .then((res) => {
                console.log(res);
                //alert("Subject added successfully");
                setSubcription(res.data);
            })
    }

    
    function AddLibrary(urlOfTheLibrary) {
        const script = document.createElement('script');
        script.src = urlOfTheLibrary;
        script.async = true;
        document.body.appendChild(script);
    }
    useEffect(() => {
        fetchTrial()
    }, [])
    return (
        <React.Fragment>
            <div class="layout-wrapper layout-content-navbar">
                <div class="layout-container">


                    <Header />
                    {error && (<div
                        class="bs-toast toast toast-placement-ex m-2 show bg-warning top-0 end-0 fade show"
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                        data-delay="2000"

                    >
                        <div class="toast-header">
                            <i class="bx bx-bell me-2"></i>
                            <div class="me-auto fw-semibold">Alert</div>
                            {/* <small>11 mins ago</small> */}
                            <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={() => { setError(false) }}></button>
                        </div>
                        <div class="toast-body">somefields are empty</div>
                    </div>)}
                    <div class="layout-page">

                        <Headerpanel />




                        <div class="content-wrapper">


                            <div class="container-xxl flex-grow-1 container-p-y">
                                <h4 class="fw-bold py-3 mb-4"><span class="text-muted fw-light">Dashboard /</span> Subscription</h4>

                                <div class="row">

                                    {/* <div class="col-md-5">
                  <div class="card mb-4">
                    <h5 class="card-header">Add Subscription</h5>
                    <div class="card-body demo-vertical-spacing demo-only-element">
                        <div>
                          <input type="hidden" value={sid} />
                            <label for="defaultFormControlInput" class="form-label">User Id</label>
                            <input
                              type="text"
                              class="form-control"
                              id="defaultFormControlInput"
                              placeholder="User Id"
                              aria-describedby="defaultFormControlHelp"
                              value={userid}
                              onChange={userid => setUserid(userid.target.value)}
                            />
                            
                          </div>
                          <div>
                          
                            <label for="defaultFormControlInput" class="form-label">Package Id</label>
                            <input
                              type="text"
                              class="form-control"
                              id="defaultFormControlInput"
                              placeholder="Package Id"
                              aria-describedby="defaultFormControlHelp"
                              value={packageid}
                              onChange={packageid => setPackageid(packageid.target.value)}
                            />
                            
                          </div>
                          <div>
                          
                            <label for="defaultFormControlInput" class="form-label">Subscription Start Date</label>
                            <input
                              type="date"
                              class="form-control"
                              id="defaultFormControlInput"
                              placeholder="Payment Mode"
                              aria-describedby="defaultFormControlHelp"
                              value={startdate}
                              onChange={startdate => setStartdate(startdate.target.value)}
                            />
                            
                          </div>
                          <div>
                          
                            <label for="defaultFormControlInput" class="form-label">Subscription End Date</label>
                            <input
                              type="date"
                              class="form-control"
                              id="defaultFormControlInput"
                              placeholder="Subscription Code"
                              aria-describedby="defaultFormControlHelp"
                              value={enddate}
                              onChange={enddate => setEnddate(enddate.target.value)}
                            />
                            
                          </div>
                          <div>
                          
                            <label for="defaultFormControlInput" class="form-label">Subscription Code</label>
                            <input
                              type="text"
                              class="form-control"
                              id="defaultFormControlInput"
                              placeholder="Subscription Code"
                              aria-describedby="defaultFormControlHelp"
                              value={subjectcode}
                              onChange={subjectcode => setSubjectcode(subjectcode.target.value)}
                            />
                            
                          </div>
                          

                      <div class="mb-3">
                        <button class="btn btn-primary d-grid w-100" type="button" style={{backgroundColor: '#188ccc'}} onClick={storeSub}>Store</button>
                        
                      </div>
                    </div>
                  </div>
                </div> */}


                                    <div class="col-md-12">
                                        <div class="card mb-4">
                                            <h5 class="card-header">Free-Trial Feedbacks</h5>
                                            <div class="card-body">
                                                <div class="table-responsive text-nowrap">
                                                    <table class="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                {/* <th>ID</th> */}
                                                                <th>Name</th>
                                                                <th>Email</th>
                                                                {/* <th>Message</th> */}
                                                                <th>feedback date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                subcription.map((obj) => {
                                                                    var sdt = new Date(obj.feedback_date);
                                                                    var separator = "/";
                                                                    return (
                                                                        <tr>
                                                                            {/* <td>
                                                                                {obj.id}
                                                                            </td> */}
                                                                            <td>{obj.sname}</td>
                                                                            <td>{obj.email}</td>
                                                                            {/* <td>{obj.msg}</td> */}
                                                                            <td>{`${sdt.getDate() < 10 ? `0${sdt.getDate()}` : `${sdt.getDate()}`}${separator}${(sdt.getMonth() + 1) < 10 ? `0${sdt.getMonth() + 1}` : `${sdt.getMonth() + 1}`}${separator}${sdt.getFullYear()}`}</td>

                                                                        </tr>
                                                                    )
                                                                })
                                                            }


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>







                            </div>

                            <footer class="content-footer footer bg-footer-theme">
                                <div class="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">

                                </div>
                            </footer>


                            <div class="content-backdrop fade"></div>
                        </div>

                    </div>

                </div>


                <div class="layout-overlay layout-menu-toggle"></div>
            </div>
            {AddLibrary("/assets/vendor/libs/jquery/jquery.js")}
            {AddLibrary("/assets/vendor/libs/popper/popper.js")}
            {AddLibrary("/assets/vendor/js/bootstrap.js")}
            {AddLibrary("/assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.js")}
            {AddLibrary("/assets/vendor/js/menu.js")}

            {AddLibrary("/assets/vendor/libs/apex-charts/apexcharts.js")}
            {AddLibrary("/assets/js/main.js")}
            {AddLibrary("/assets/js/dashboards-analytics.js")}
        </React.Fragment>
    )
}

export default TrialFeedback;
